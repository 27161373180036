import React from "react";
import {
    Spin,
    Card,
    Table,
    Descriptions,
    Button,
    Steps,
    Form,
    Row,
    Col,
    Input,
    Select,
    DatePicker,
    message,
    Dropdown,
    Menu,
    Popconfirm,
    Modal,
    Upload
} from "antd";
import { Link } from "react-router-dom";
import { SearchOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';

import LinkButton from "../../../../../components/link-button";
import {
    SOURCE_TYPE_OBJ,
    AWARDQUALITY,
    AWARDTYPE,
    AWARDTARGET,
    AWARDMILESTONE,
    AWARDSTATE,
    AUTH,
    ROLES
} from "../../../../../utils/constant";
import tools from "../../../../../utils/tools";
import { moaApi } from "../../../../../api";
import memory from "../../../../../utils/memory";
import moment from "moment";
import storage from "../../../../../utils/storage";

const { unixToTime } = tools;
const {
    reqAwardsDetail,
    reqContactsList,
    reqAwardsSchedule,
    reqAwardsScheduleEdit,
    reqAwardsScheduleCommit,
    reqAwardsScheduleWorker,
    reqAwardsScheduleJump
} = moaApi;
const {
    ADMIN,
    MOAADMIN
} = ROLES;
const { Step } = Steps;
const stepStyle = {
    marginBottom: 60,
    boxShadow: '0px -1px 0 0 #e8e8e8 inset',
};
const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { confirm } = Modal;
let id = 0;

class AwardsDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
        current: 0,
        contacts: [],
        paginationContacts: {},
        schedule: {}, //进度管理
        tmpCurrent: 0,
        fileList: [],
        managerFileList: [],
        editStatus: false,
        teamWorker:[],
        renderPartners:"none",
        
    };
    formRef=React.createRef()
    adminAction = false; // 管理员操作（管理员、MOA管理员）
    id = this.props.match.params.id;
    arriveColumns = [
        {
            title: '到账金额',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '到账时间',
            dataIndex: 'arrived_time',
            key: 'arrived_time',
        },
        {
            title: '付款单位',
            dataIndex: 'payer',
            key: 'payer',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record) => {
                return (
                    <Link to={`/moa/capital/arrive/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];
    expenseColumns = [
        {
            title: '支出金额',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '支出时间',
            dataIndex: 'expenses_time',
            key: 'expenses_time',
        },
        {
            title: '支出说明',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record) => {
                return (
                    <Link to={`/moa/capital/expense/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];
    /*流程管理*/
    stage = [
        {
            value: 2,
            wait: '申报阶段',
            finish: '已申报',
            button: '完成申报',
            success: '申报成功',
        },
        {
            value: 3,
            wait: '评审阶段',
            finish: '已评审',
            button: '完成评审',
            success: '评审成功',
        },
        {
            value: 4,
            wait: '结项阶段',
            finish: '已结项',
            button: '完成结项',
            success: '结项成功',
        },
        {
            value: 5,
            wait: '总结阶段',
            finish: '已总结',
            button: '完成总结',
            success: '总结成功',
        },
    ];

    onChange = tmpCurrent => {
        const { schedule } = this.state;
        let fileList = [];
        let managerFileList = [];
        const { setFieldsValue } = this.formRef.current;
        //console.log("enter here")
        if (tmpCurrent >= 3) {
            managerFileList = schedule.accomplish_management.files || [];
        }
        if (schedule.schedule && schedule.schedule.length) {
            const currentSchedule = schedule.schedule[tmpCurrent];
            if (currentSchedule) {
                managerFileList = currentSchedule.files || [];
            }
            if (currentSchedule && currentSchedule.team_worker && currentSchedule.team_worker.length) {
                const teamWorker = currentSchedule.team_worker;
                for (let i = 0; i < teamWorker.length; i++) {
                    if (teamWorker[i].id === memory.user._id) {
                        fileList = teamWorker[i].files;
                    }
                }
                const arr = Array.from({ length: teamWorker.length }, (item, index) => index);
                id = teamWorker.length;
                setFieldsValue({
                    keys: arr,
                });
                this.setState({ tmpCurrent, fileList, managerFileList });
            } else {
                id = 0;
                setFieldsValue({
                    keys: [],
                });
                this.setState({ tmpCurrent, fileList, managerFileList });
            }
        } else {
            id = 0;
            setFieldsValue({
                keys: [],
            });
            this.setState({ tmpCurrent, fileList, managerFileList });
        }
    };

    onChangeFile = async ({ file }) => {
        const { fileList } = this.state;
        let params = [...fileList];
        if (file.status === 'done') {
            params.push({
                uid: file.uid,
                name: file.name,
                status: file.status,
                response: 'Server Error 500',
                url: file.response.url,
                create_at: (new Date()).getTime()
            });

            const resposne = await reqAwardsScheduleWorker({
                id: this.state.schedule._id,
                files: params,
                stage: (this.state.tmpCurrent + 2) > 5 ? 5 : (this.state.tmpCurrent + 2)
            });
            if (resposne && resposne.data) {
                message.success('文件更新成功');
                /*this.setState({
                    fileList: params
                });*/
                // 获取奖项项目进度
                this.getFundSchedule({
                    award_id: this.id
                });
            }
        } else if (file.status === 'error') {
            message.error('文件上传失败');
        }
    };
    onChangeManagerFile = async ({ file }) => {
        const { managerFileList } = this.state;
        let params = [...managerFileList];
        let index = params.length;
        if (file.status === 'done') {
            params.push({
                key: file.uid,
                uid: file.uid,
                index: index + 1,
                name: file.name,
                status: file.status,
                response: 'Server Error 500',
                url: file.response.url,
                create_at: (new Date()).getTime()
            });

            const resposne = await reqAwardsScheduleEdit({
                id: this.state.schedule._id,
                files: params,
                stage: (this.state.tmpCurrent + 2) > 5 ? 5 : (this.state.tmpCurrent + 2)
            });
            if (resposne && resposne.data) {
                message.success('文件更新成功');
                /*this.setState({
                    managerFileList: params
                });*/
                // 获取奖项项目进度
                this.getFundSchedule({
                    award_id: this.id
                });
            }
        } else if (file.status === 'error') {
            message.error('文件上传失败');
        }
    };

    onRemoveFile = async (record) => {
        let { fileList } = this.state;
        fileList = fileList.filter(item => item.uid !== record.uid);
        const resposne = await reqAwardsScheduleWorker({
            id: this.state.schedule._id,
            files: fileList,
            stage: (this.state.tmpCurrent + 2) > 5 ? 5 : (this.state.tmpCurrent + 2)
        });
        if (resposne && resposne.data) {
            message.success('文件删除成功');
            //this.setState({fileList});
            // 获取奖项项目进度
            this.getFundSchedule({
                award_id: this.id
            });
        }
    };
    onRemoveManagerFile = async (record) => {
        let { managerFileList } = this.state;
        managerFileList = managerFileList.filter(item => item.uid !== record.uid);
        const resposne = await reqAwardsScheduleEdit({
            id: this.state.schedule._id,
            files: managerFileList,
            stage: (this.state.tmpCurrent + 2) > 5 ? 5 : (this.state.tmpCurrent + 2)
        });
        if (resposne && resposne.data) {
            message.success('文件删除成功');
            //this.setState({managerFileList});
            // 获取奖项项目进度
            this.getFundSchedule({
                award_id: this.id
            });
        }
    };

    handlePreview = async (file) => {
        tools.downloadFile(this.props.history, file, `${memory.user.dd_info.name} ${moment().format("YYYY/MM/DD")}`);
    }

    remove = index => {
        const { getFieldValue, setFieldsValue } = this.formRef.current;
        // can use data-binding to get
        const keys = getFieldValue('keys') || [];
        // We need at least one passenger
        /*if (keys.length === 1) {
            return;
        }*/

        // can use data-binding to set
        id--;
        keys.splice(index,1);
        let tempTeamWork=this.state.teamWorker
        //console.log('删除目标',index,JSON.parse(JSON.stringify(tempTeamWork)))
        tempTeamWork.splice(index,1)
        setFieldsValue({
            //keys: keys.filter(key => key !== k),
            keys
        });
        //console.log("new Worker",tempTeamWork)
        this.setState({
            renderPartners:Math.random().toString(32).slice(2,10),
            teamWorker:tempTeamWork
        })
    };

    add = (teamWorker=this.state.teamWorker) => {
        const { getFieldValue, setFieldsValue } = this.formRef.current;
        // can use data-binding to get
        let keys = getFieldValue('keys') || []
        if(keys.length==0){
            keys=Array.from({ length: teamWorker.length }, (item, index) => index);
        }
        id=(keys[keys.length-1] || 0)
        const nextKeys = keys.concat(id+1);
        // can use data-binding to set
        // important! notify form to detect changes
        setFieldsValue({
            keys: nextKeys,
        });
        this.formRef.current.setFieldValue('keys',nextKeys)
        this.setState({
            teamWorker:teamWorker.concat({
                
            }),
            renderPartners:Math.random().toString(32).slice(2,10)
        })
    };

    handleMenuClick = (e) => {
        const {
            form: { resetFields }
        } = this.props;
        const that = this;
        confirm({
            title: '变更阶段',
            content: e.key === '1' ? `确定${this.stage[this.state.tmpCurrent].button}吗？` : (e.key === '2' ? '确定进入流失阶段吗？' : '确定重置回该阶段吗？'),
            async onOk() {
                let params = {
                    id: that.state.schedule._id
                };
                if (e.key === '1') {
                    //进入下一阶段
                    params.is_confirm = true;
                } else if (e.key === '2') {
                    //进入流失阶段
                    params.wastage = true;
                } else {
                    //重置回该阶段
                    params.stage = that.state.tmpCurrent + 2
                }
                let response;
                if (e.key === '1' || e.key === '2') {
                    response = await reqAwardsScheduleCommit(params);
                } else {
                    response = await reqAwardsScheduleJump(params);
                }
                if (response && response.data) {
                    if (e.key === '1') {
                        message.success(that.stage[that.state.tmpCurrent].success);
                    } else if (e.key === '2') {
                        message.success('项目进入流失阶段');
                    } else {
                        message.success(`项目已重置回${that.stage[that.state.tmpCurrent].wait}`);
                    }
                    resetFields();
                    that.getAwardsDetail({
                        id: that.id
                    });
                }
            }
        });
    };

    handleStageSave = () => {
        if(!this.formRef.current) return
        const { validateFields, resetFields 
        } = this.formRef.current;
        validateFields()
        .then(async (values) => {
            let params = {};
            if (this.state.tmpCurrent >= 3 || (this.state.schedule.accomplish_management.type === 2 && this.state.tmpCurrent === this.state.current)) {
                params = {
                    id: this.state.schedule._id,
                    stage: 5,
                    summary: values.summary
                };
            } else {
                params = {
                    id: this.state.schedule._id,
                    stage_target: values.stage_target,
                    team_worker: [],
                    stage: this.state.tmpCurrent + 2,
                    sub_summary: values.sub_summary
                };
                if (values.stage_time && values.stage_time.length) {
                    params.start_time = new Date(values.stage_time[0]._d);
                    params.end_time = new Date(values.stage_time[1]._d);
                }
                let workers=[],targets=[],planTimes=[],workerIndex=-1,targetIndex=-1,planTimeIndex=-1
                for(let key in values){
                    if(key.startsWith("target[")){
                        targetIndex++;//key.slice(7,key.length-1)*1
                        targets[targetIndex]=values[key]
                    }else if(key.startsWith("worker[")){
                        workerIndex++//=key.slice(7,key.length-1)*1
                        workers[workerIndex]=values[key]
                    }else if(key.startsWith("plan_time[")){
                        planTimeIndex++//=key.slice(10,key.length-1)*1
                        planTimes[planTimeIndex]=values[key]
                    }
                }
                if (workers.length) {
                    for (let i = 0; i < workers.length; i++) {
                        params.team_worker.push({
                            id: workers[i],
                            start_at: new Date(planTimes[i][0]._d),
                            end_at: new Date(planTimes[i][1]._d),
                            target: targets[i]
                        });
                    }
                }
            }
            const response = await reqAwardsScheduleEdit(params);
            if (response && response.data) {
                message.success('设置成功');
                resetFields();
                this.getAwardsDetail({
                    id: this.id
                });
                this.handleEditChange(false);
            }
        });
    };

    handleEditChange = (status) => {
        this.setState({
            renderPartners:Math.random().toString(32).slice(2,10),
            editStatus: status,
        });
    };

    handleTableChangeContacts = (pagination, filters) => {
        const pager = { ...this.state.paginationContacts };
        pager.current = pagination.current;
        this.setState({
            paginationContacts: pager
        });
        let tmpFilters = { ...filters };
        for (let item in tmpFilters) {
            if (tmpFilters[item]) {
                if (typeof (tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            award: this.id,
            ...tmpFilters,
        };
        this.getContactsList(params);
    };
    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys ? selectedKeys[0] : ''}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };
    contactsColumns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            ...this.getColumnSearchProps('name', '姓名')
        },
        {
            title: '职务',
            dataIndex: 'post',
            key: 'post',
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '微信号',
            dataIndex: 'wechat',
            key: 'wechat',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '办公座机',
            dataIndex: 'telephone',
            key: 'telephone',
        },
        {
            title: '地址',
            dataIndex: 'place',
            key: 'place',
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record, index) => {
                return (
                    <Link to={`/moa/awards/contacts/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];
    getContactsList = async (params = {}) => {
        const response = await reqContactsList(params);
        const pagination = { ...this.state.paginationContacts };
        if (response && response.data) {
            const data = response.data;
            pagination.total = data.page.total;
            pagination.pageSize = data.page.node;
            const newData = [];
            for (let i = 0; i < data.data.length; i++) {
                const item = data.data[i];
                let obj = {
                    key: item._id,
                    name: item.name,
                    post: item.post || '-',
                    mobile: item.mobile || '-',
                    wechat: item.wechat || '-',
                    email: item.email || '-',
                    telephone: item.telephone || '-',
                    place: item.place || '-',
                    create_time: unixToTime((new Date(item.create_at)).getTime() / 1000),
                };
                newData.push(obj);
            }
            this.setState({
                contacts: newData,
                paginationContacts: pagination
            });
        }
    };

    getFundSchedule = async (params = {}) => {
        const response = await reqAwardsSchedule(params);
        if (response && response.data) {
            const { schedule } = response.data;
            const { tmpCurrent } = this.state;
            let fileList = [];
            let managerFileList = [];
            if (schedule && schedule.length) {
                const currentSchedule = schedule[tmpCurrent];
                if (currentSchedule) {
                    managerFileList = currentSchedule.files || [];
                }
                if (currentSchedule && currentSchedule.team_worker && currentSchedule.team_worker.length) {
                    const teamWorker = currentSchedule.team_worker;
                    for (let i = 0; i < teamWorker.length; i++) {
                        if (teamWorker[i].id === memory.user._id) {
                            fileList = teamWorker[i].files;
                        }
                    }
                    id = currentSchedule.team_worker.length;
                } else {
                    id = 0;
                }
            } else {
                id = 0;
            }
            if (tmpCurrent >= 3) {
                managerFileList = response.data.accomplish_management.files || [];
            }
            let cur = tmpCurrent;
            /*if(response.data.accomplish_management.type === 2) {
                cur = cur-1;
            }*/
            this.setState({
                schedule: response.data,
                fileList: fileList,
                managerFileList: managerFileList,
                loading: false,
                tmpCurrent: cur
            })
        }
    };

    getAwardsDetail = async (params = {}) => {
        const response = await reqAwardsDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data,
                current: response.data.stage - 2,
                tmpCurrent: response.data.stage - 2,
            });
        let teamWorker=[],existedWorker={};
        (response.data?.schedule || [])
        .map(item=>{
            if(item.team_worker){
                item.team_worker.map((worker)=>{
                    if(existedWorker[worker.id]) return
                    existedWorker[worker.id]=1
                    teamWorker.push(worker)
                })
            }
        })
        //判断成员内容
        this.setState({
            teamWorker:teamWorker
        })
        }

        // 获取联络人
        this.getContactsList({
            award: this.id,
            page: 1,
        });
        // 获取奖项项目进度
        this.getFundSchedule({
            award_id: this.id
        });
    };

    componentWillMount() {
        if (memory.user.role_type.indexOf(ADMIN) > -1 || memory.user.role_type.indexOf(MOAADMIN) > -1) {
            this.adminAction = true;
        }
    }

    componentDidMount() {
        // 获取详情
        this.getAwardsDetail({
            id: this.id
        });
    }

    render() {
        const { detail, current, tmpCurrent, schedule, fileList, managerFileList } = this.state;
        let currentSchedule = {};
        let initIds = [];
        let teamWorker = [];
        if (schedule.schedule && schedule.schedule.length) {
           
            currentSchedule = schedule.schedule[tmpCurrent];
            if (currentSchedule && currentSchedule.team_worker && currentSchedule.team_worker.length) {
                teamWorker = currentSchedule.team_worker;
                //console.log("teamworker here",teamWorker,this.state.teamWorker)
                if(teamWorker.length!==this.state.teamWorker.length){
                    this.setState({
                        teamWorker:teamWorker
                    })
                }
                
                initIds = Array.from({ length: currentSchedule.team_worker.length }, (item, index) => index);
            } else {
                initIds = [];
                teamWorker = this.state.teamWorker || [];
                //console.log("from here")
            }
        }
        
        let workers = [];
        if (detail.team_worker && detail.team_worker.length) {
            for (let j = 0; j < detail.team_worker.length; j++) {
                workers.push(detail.team_worker[j].name);
            }
        }

        let headers = {};
        const auth = storage.getStore(AUTH);
        if (auth) {
            headers = {
                authorization: 'Bearer ' + auth
            };
        }
        const props = {
            action: '/api/files/ue?action=uploadfile',
            headers: headers,
            onPreview: this.handlePreview,
        };

        let managerColumns = [
            {
                title: 'No.',
                dataIndex: 'index',
                key: 'index'
            },
            {
                title: '文件名',
                dataIndex: 'name',
                key: 'name',
                render: (_, record) => {
                    return (
                        <a href={record.url} target="_blank">{record.name}</a>
                    );
                }
            },
            {
                title: '上传时间',
                dataIndex: 'create_at',
                key: 'create_at',
                render: (_, record) => {
                    return (
                        tools.unixToTime(record.create_at / 1000)
                    );
                }
            },
        ];
        if ((detail.manager && detail.manager.id === memory.user._id) || this.adminAction) {
            managerColumns.push(
                {
                    title: '操作',
                    dataIndex: 'option',
                    valueType: 'option',
                    render: (_, record) => {
                        return (
                            <Popconfirm
                                title="确定删除该文件吗?"
                                onConfirm={() => this.onRemoveManagerFile(record)}
                                okText="确定"
                                cancelText="取消"
                            >
                                <a>删除</a>
                            </Popconfirm>
                        );
                    }
                }
            );
        }

        let formItems = null;
        if (this.formRef.current || teamWorker.length>0) {
            //this.formRef.current.getFieldDecorator('keys', { initialValue: initIds });
            let keys = (this.formRef.current && this.formRef.current.getFieldValue('keys')) || [];
            if(keys.length==0){
                keys=Array.from({ length: teamWorker.length }, (item, index) => index)
            }
            
            formItems = keys.map((k,index) => {
                let defaultFileList = [];
                let myFiles = [];
                if (teamWorker[index]) {
                    if (teamWorker[index].id === memory.user._id) {
                        myFiles = [...fileList];
                    } else {
                        myFiles = [...(teamWorker[index]?.files || [])];
                    }
                    for (let i = 0; i < myFiles.length; i++) {
                        myFiles[i].key = myFiles[i].uid;
                        myFiles[i].index = i + 1;
                        defaultFileList.push(myFiles[i]);
                    }
                }
                let columns = [
                    {
                        title: 'No.',
                        dataIndex: 'index',
                        key: 'index'
                    },
                    {
                        title: '文件名',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => {
                            return (
                                <a href={record.url} target="_blank">{record.name}</a>
                            );
                        }
                    },
                    {
                        title: '上传时间',
                        dataIndex: 'create_at',
                        key: 'create_at',
                        render: (_, record) => {
                            return (
                                tools.unixToTime(record.create_at / 1000)
                            );
                        }
                    },
                ];
                if (teamWorker[index] && teamWorker[index].id === memory.user._id) {
                    columns.push(
                        {
                            title: '操作',
                            dataIndex: 'option',
                            valueType: 'option',
                            render: (_, record) => {
                                return (
                                    <Popconfirm
                                        title="确定删除该文件吗?"
                                        onConfirm={() => this.onRemoveFile(record)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <a>删除</a>
                                    </Popconfirm>
                                );
                            }
                        }
                    );
                }
                //console.log(JSON.parse(JSON.stringify(teamWorker)),index)
                if ((detail.manager.id === memory.user._id || this.adminAction) && this.state.editStatus === true) {
                    return (
                        <div key={index} style={{ borderTop: '1px solid #d9d9d9', paddingTop: 12, marginTop: 12 }}>
                            <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6} key={index}>
                                    <Form.Item
                                        label={`成员${index + 1}`}
                                        name={`worker[${k}]`}
                                        initialValue={teamWorker[index] && teamWorker[index].id ? teamWorker[index].id : []}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择成员",
                                            },
                                        ]}
                                    >
                                        <Select placeholder="请选择成员" style={{ marginRight: 8 }}>
                                            {
                                                detail.team_worker.map((item) => (
                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="任务时间"
                                        name={`plan_time[${k}]`}
                                        initialValue={teamWorker[index] && teamWorker[index].start_at ? [moment(teamWorker[index].start_at), moment(teamWorker[index].end_at)] : []}
                                        rules={[{ type: 'array', required: true, message: '请选择任务时间' }]}
                                    >
                                        <RangePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="任务"
                                        name={`target[${k}]`}
                                        initialValue={teamWorker[index] && teamWorker[index].target || ''}
                                        rules={[{ required: true, message: '请填写该成员的任务内容' }]}
                                    >
                                        <TextArea rows={4} placeholder="请填写该成员的任务内容" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Popconfirm
                                        title="确定删除该成员吗?"
                                        onConfirm={() => this.remove(index)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <Button type="primary" style={{ float: 'right', marginBottom: 24 }}>删除该成员</Button>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        </div>
                    );
                } else {
                    return (
                        <div key={index} style={{ borderTop: '1px solid #d9d9d9', paddingTop: 12, marginTop: 24 }}>
                            <Descriptions>
                                <Descriptions.Item label={`成员${index+ 1}`}>
                                    {teamWorker[index].name || '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label="任务时间">
                                    {teamWorker[index].start_at ? (tools.unixToTime((new Date(teamWorker[index].start_at)).getTime() / 1000) + ' ~ ' + tools.unixToTime((new Date(teamWorker[index].end_at)).getTime() / 1000)) : '-'}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions
                                column={1}
                            >
                                <Descriptions.Item label="任务">
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        {teamWorker[index].target || '无'}
                                    </div>
                                </Descriptions.Item>
                            </Descriptions>
                            <div style={{ marginTop: 12 }}>
                                <Table
                                    title={() => <span style={{ marginLeft: -8, color: 'rgba(0, 0, 0, 0.85)' }}>{`成员${k + 1} 文件列表：`}</span>}
                                    columns={columns}
                                    dataSource={defaultFileList}
                                    pagination={false}
                                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                    size={memory.is_mobile ? 'small' : 'middle'}
                                />
                            </div>

                            {
                                teamWorker[index].id === memory.user._id ? (
                                    <div style={{ marginTop: 12, textAlign: 'right' }}>
                                        <Upload {...props} showUploadList={false} onChange={this.onChangeFile}>
                                            <Button>
                                                <UploadOutlined />上传文件
                                            </Button>
                                        </Upload>
                                    </div>
                                ) : null
                            }
                        </div>
                    );
                }
            });
        }

        let menu = '';
        if (tmpCurrent < 4) {
            menu = (
                <Menu onClick={this.handleMenuClick}>
                    {
                        tmpCurrent === current ? (
                            <Menu.Item key="1">{this.stage[tmpCurrent].button}</Menu.Item>
                        ) : null
                    }
                    {
                        tmpCurrent < current ? (
                            <Menu.Item key="3">重置回该阶段</Menu.Item>
                        ) : null
                    }
                    {
                        !schedule.accomplish_management || !schedule.accomplish_management.type ? (
                            <Menu.Item key="2">流失</Menu.Item>
                        ) : null
                    }
                </Menu>
            );
        }
        const formComponent = () => {
            if ((detail.manager.id === memory.user._id || this.adminAction) && this.state.editStatus === true) {
                if (schedule.accomplish_management.type === 2 && tmpCurrent === current) {
                    return (
                        <Form ref={this.formRef}>
                            <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="流失总结"
                                        name="summary"
                                        initialValue={schedule.accomplish_management.summary || ''}
                                    >
                                        <TextArea rows={4} placeholder="请填写流失总结" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div style={{ float: 'right' }}>
                                        <Button style={{ marginRight: 12 }} onClick={() => this.handleEditChange(false)}>退出管理模式</Button>
                                        <Button type="primary" onClick={this.handleStageSave}>保存</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    );
                }
                if (schedule.accomplish_management.type === 1 && tmpCurrent >= 3) {
                    return (
                        <Form ref={this.formRef}>
                            <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="项目总结"
                                        name="summary"
                                        initialValue={schedule.accomplish_management.summary || ''}
                                    >
                                        <TextArea rows={4} placeholder="请填写项目总结" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div style={{ float: 'right' }}>
                                        <Button style={{ marginRight: 12 }} onClick={() => this.handleEditChange(false)}>退出管理模式</Button>
                                        <Button type="primary" onClick={this.handleStageSave}>保存</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    );
                } else {
                    return (
                        <Form ref={this.formRef} >
                            <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="阶段目标"
                                        name="stage_target"
                                        initialValue={currentSchedule.stage_target || ''}
                                    >
                                        <TextArea rows={4} placeholder="请填写阶段目标" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="阶段时间"
                                        name="stage_time"
                                        initialValue={currentSchedule.start_time ? [moment(currentSchedule.start_time), moment(currentSchedule.end_time)] : []}
                                    >
                                        <RangePicker showTime format="YYYY-MM-DD HH:mm" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {formItems}
                            <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item name="keys">
                                        <Button type="dashed" onClick={()=>this.add(teamWorker)} style={{ width: '100%' }}>
                                            <PlusOutlined /> 添加成员
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        label="阶段总结"
                                        name="sub_summary"
                                        initialValue={currentSchedule.sub_summary || ''}
                                    >
                                        <TextArea rows={4} placeholder="请填写阶段总结" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div style={{ float: 'right' }}>
                                        <Button style={{ marginRight: 12 }} onClick={() => this.handleEditChange(false)}>退出管理模式</Button>
                                        <Button type="primary" onClick={this.handleStageSave}>保存</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    );
                }
            } else {
                if (schedule.accomplish_management.type === 2 && tmpCurrent === current) {
                    return (
                        <>
                            <Descriptions
                                column={1}
                                style={{ paddingTop: 24, marginTop: 24 }}
                            >
                                <Descriptions.Item label="流失总结">
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        {schedule && schedule.accomplish_management.summary ? schedule.accomplish_management.summary : '无'}
                                    </div>
                                </Descriptions.Item>
                                {
                                    schedule && schedule.accomplish_management.commit_time ? (
                                        <Descriptions.Item label="阶段完成时间">
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                {tools.unixToTime((new Date(schedule.accomplish_management.commit_time)).getTime() / 1000)}
                                            </div>
                                        </Descriptions.Item>
                                    ) : null
                                }
                            </Descriptions>
                            {
                                detail.manager.id === memory.user._id || this.adminAction ? (
                                    <div style={{ float: 'right' }}>
                                        <Button type="primary" onClick={() => this.handleEditChange(true)}>
                                            管理进度
                                        </Button>
                                    </div>
                                ) : null
                            }
                        </>
                    )
                }
                if (tmpCurrent >= 3) {
                    return (
                        <>
                            <Descriptions
                                column={1}
                                style={{ paddingTop: 24, marginTop: 24 }}
                            >
                                <Descriptions.Item label="项目总结">
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        {schedule && schedule.accomplish_management.summary ? schedule.accomplish_management.summary : '无'}
                                    </div>
                                </Descriptions.Item>
                                {
                                    schedule && schedule.accomplish_management.commit_time ? (
                                        <Descriptions.Item label="阶段完成时间">
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                {tools.unixToTime((new Date(schedule.accomplish_management.commit_time)).getTime() / 1000)}
                                            </div>
                                        </Descriptions.Item>
                                    ) : null
                                }
                            </Descriptions>

                            <div style={{ marginTop: 12, marginBottom: 12 }}>
                                <Table
                                    title={() => <span style={{ marginLeft: -8, color: 'rgba(0, 0, 0, 0.85)' }}>项目经理 文件列表：</span>}
                                    columns={managerColumns}
                                    dataSource={managerFileList}
                                    pagination={false}
                                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                    size={memory.is_mobile ? 'small' : 'middle'}
                                />
                            </div>
                            {
                                detail.manager.id === memory.user._id || this.adminAction ? (
                                    <div style={{ marginTop: 12, marginBottom: 24, textAlign: 'right' }}>
                                        <Upload {...props} showUploadList={false} onChange={this.onChangeManagerFile}>
                                            <Button>
                                                <UploadOutlined />上传文件
                                            </Button>
                                        </Upload>
                                    </div>
                                ) : null
                            }

                            {
                                detail.manager.id === memory.user._id || this.adminAction ? (
                                    <div style={{ float: 'right' }}>
                                        {
                                            !schedule.accomplish_management || schedule.accomplish_management.type !== 2 && tmpCurrent < 4 ? (
                                                <Dropdown.Button overlay={menu} type="primary" onClick={() => this.handleEditChange(true)}>
                                                    管理进度
                                                </Dropdown.Button>
                                            ) : (
                                                <Button type="primary" onClick={() => this.handleEditChange(true)}>
                                                    管理进度
                                                </Button>
                                            )
                                        }
                                    </div>
                                ) : null
                            }
                        </>
                    )
                } else {
                    return (
                        <>
                            <Descriptions
                                column={1}
                            >
                                <Descriptions.Item label="阶段目标">
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        {currentSchedule && currentSchedule.stage_target ? currentSchedule.stage_target : '无'}
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="阶段时间">
                                    {currentSchedule && currentSchedule.start_time ? (tools.unixToTime((new Date(currentSchedule.start_time)).getTime() / 1000) + ' ~ ' + tools.unixToTime((new Date(currentSchedule.end_time)).getTime() / 1000)) : '-'}
                                </Descriptions.Item>
                            </Descriptions>
                            {formItems}
                            <Descriptions
                                column={1}
                                style={{ borderTop: '1px solid #d9d9d9', paddingTop: 24, marginTop: 24 }}
                            >
                                <Descriptions.Item label="阶段总结">
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        {currentSchedule && currentSchedule.sub_summary ? currentSchedule.sub_summary : '无'}
                                    </div>
                                </Descriptions.Item>
                                {
                                    currentSchedule && currentSchedule.commit_time ? (
                                        <Descriptions.Item label="阶段完成时间">
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                {tools.unixToTime((new Date(currentSchedule.commit_time)).getTime() / 1000)}
                                            </div>
                                        </Descriptions.Item>
                                    ) : null
                                }
                            </Descriptions>
                            <div style={{ marginTop: 12, marginBottom: 12 }}>
                                <Table
                                    title={() => <span style={{ marginLeft: -8, color: 'rgba(0, 0, 0, 0.85)' }}>项目经理 文件列表：</span>}
                                    columns={managerColumns}
                                    dataSource={managerFileList}
                                    pagination={false}
                                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                    size={memory.is_mobile ? 'small' : 'middle'}
                                />
                            </div>
                            {
                                detail.manager.id === memory.user._id || this.adminAction ? (
                                    <div style={{ marginTop: 12, marginBottom: 24, textAlign: 'right' }}>
                                        <Upload {...props} showUploadList={false} onChange={this.onChangeManagerFile}>
                                            <Button>
                                                <UploadOutlined />上传文件
                                            </Button>
                                        </Upload>
                                    </div>
                                ) : null
                            }

                            {
                                detail.manager.id === memory.user._id || this.adminAction ? (
                                    <div style={{ float: 'right' }}>
                                        {
                                            !schedule.accomplish_management || schedule.accomplish_management.type === 0 ? (
                                                <Dropdown.Button overlay={menu} type="primary" onClick={() => this.handleEditChange(true)}>
                                                    管理进度
                                                </Dropdown.Button>
                                            ) : (
                                                <Button type="primary" onClick={() => this.handleEditChange(true)}>
                                                    管理进度
                                                </Button>
                                            )
                                        }
                                    </div>
                                ) : null
                            }
                        </>
                    );
                }
            }
        };

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="进度管理"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Steps type="navigation" current={tmpCurrent > 3 ? 3 : tmpCurrent} onChange={this.onChange} style={stepStyle}>
                        {
                            schedule.accomplish_management.type === 0 ?
                                this.stage.map((item, index) => {
                                    if (current > index) {
                                        return <Step key={index} status="finish" title={item.finish} />;
                                    } else if (current === index) {
                                        return <Step key={index} status="process" title={item.wait} />;
                                    } else {
                                        return <Step key={index} status="wait" disabled title={item.wait} />;
                                    }
                                }) :
                                this.stage.map((item, index) => {
                                    if (schedule.accomplish_management.type === 1) {
                                        if (current > index) {
                                            return <Step key={index} status="finish" title={item.finish} />;
                                        } else if (current === index) {
                                            return <Step key={index} status="process" title={item.wait} />;
                                        }
                                    } else {
                                        if (current > index) {
                                            return <Step key={index} status="finish" title={item.finish} />;
                                        }
                                        if (current === index) {
                                            return <Step key={index} status="process" title="已流失" />
                                        }
                                    }
                                })
                        }
                    </Steps>
                    {formComponent()}
                </Card>

                <Card
                    title="奖项信息"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/awards/edit/${this.id}`} txt="编辑" />}
                >
                    <Descriptions
                        title="基本信息"
                    >
                        <Descriptions.Item label="奖项申报年份">
                            {detail.award_date || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="奖项名称">
                            {detail.award_name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="奖项平台">
                            {detail.platform && detail.platform.name ? detail.platform.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="奖项信息链接">
                            {
                                detail.official_website ?
                                    <a href={detail.official_website} target='_blank'>{detail.official_website}</a> : '-'
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="奖项质量">
                            {detail.award_quality ? AWARDQUALITY[detail.award_quality - 1].text : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="奖项性质">
                            {detail.award_type !== undefined ? AWARDTYPE[detail.award_type - 1].text : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="面向对象">
                            {detail.oriented_target !== undefined ? AWARDTARGET[detail.oriented_target - 1].text : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="项目经理">
                            {detail.manager.name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="协作者">
                            {workers.length ? workers.join('、') : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="申请截止时间">
                            {detail.data_deadline ? tools.unixToTime((new Date(detail.data_deadline)).getTime() / 1000) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="里程碑">
                            {schedule.accomplish_management && schedule.accomplish_management.type === 2 ? '已流失' : AWARDMILESTONE[detail.milestone - 1].text}
                        </Descriptions.Item>
                        <Descriptions.Item label="所处阶段">
                            {AWARDSTATE[detail.stage - 1].text}
                        </Descriptions.Item>
                        <Descriptions.Item label="代理机构">
                            {detail.agency && detail.agency.name ? <a href={`/moa/awards/agency/detail/${detail.agency.id}`} target="_blank">{detail.agency.name}</a> : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="备注">
                            {detail.remark || '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        column={1}
                    >
                        <Descriptions.Item label="奖项简介">
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {detail.brief || '无'}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="申报信息"
                        style={{
                            marginBottom: 24,
                        }}
                        column={1}
                    >
                        <Descriptions.Item label="申报条件">
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {detail.declare_condition || '无'}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="申报步骤">
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {detail.declare_step || '无'}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="评奖标准">
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {detail.standard_of_review || '无'}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="创建信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Descriptions.Item label="创建人">
                            {detail.creator && detail.creator.name ? detail.creator.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建时间">
                            {detail.create_at
                                ? unixToTime(new Date(detail.create_at).getTime() / 1000)
                                : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="信息来源类型">
                            {detail.source_type ? SOURCE_TYPE_OBJ[detail.source_type] : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="信息来源渠道">
                            {detail.source_channel || '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card
                    title="申报联络管理"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/awards/contacts/add/${this.id}`} icon="plus" txt="添加联络人" />}
                >
                    <Table
                        columns={this.contactsColumns}
                        dataSource={this.state.contacts}
                        pagination={this.state.paginationContacts}
                        onChange={this.handleTableChangeContacts}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>
            </>
        );
    }
}

export default AwardsDetail;